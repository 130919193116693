.loader-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-text-container {
  margin-top: 40px;
}

.loader-text {
  font-size: 20px;
  color: #777777;
  opacity: 0.5;
  text-align: center;
  font-family: Lato;
}
