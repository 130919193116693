@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;900&display=swap);
.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
img.non-scrolling.non-zoomed {
  width: 100vw;
  height: 100vh;
  object-fit: contain;
}
.last-refresh-timestamp {
  bottom: 2%;
  position: absolute;
  right: 2%;
  background: #fccf00;
  padding: 5px;
  border-radius: 10px;
  font-size: 14px;
}
.last-refresh-timestamp-text {
  display: inline-block;
  padding-top: 1px;
  padding-right: 5px;
  padding-left: 4px;
}
.last-refresh-timestamp-job-error {
  bottom: 2%;
  position: absolute;
  right: 2%;
  background: #f03;
  padding: 5px;
  border-radius: 10px;
  font-size: 14px;
  color: #fff;
}
.last-refresh-timestamp-job-error-warning-icon {
  width: 20px;
  height: 20px;
  margin-right: 6px;
  padding-left: 4px;
  float: left;
 /** Same as color: #ffffff **/
  filter: invert(100%) sepia(100%) saturate(29%) hue-rotate(116deg) brightness(106%) contrast(108%);
}

.loader-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-text-container {
  margin-top: 40px;
}

.loader-text {
  font-size: 20px;
  color: #777777;
  opacity: 0.5;
  text-align: center;
  font-family: Lato;
}

.error-panel {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}
h1, h2, p {
  margin: 0;
  padding: 0;
}
h1:first-letter, h2:first-letter, p:first-letter {
  text-transform: capitalize;
}
.error-container {
  position: fixed;
  z-index: 99;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: #f2f2f2;
  margin-bottom: 10;
  margin-right: 10;
  display: flex;
}
.error-container .left-panel {
  background-color: #fecf00;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
  padding: 2vw;
}
.error-container .right-panel {
  padding: 2vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.error-container-tickertape .left-panel {
  width: 110vh;
}
.error-container-tickertape .left-panel img {
  height: 60vh;
}
.error-container-tickertape .right-panel {
  margin-left: 30vh;
}
.error-container-tickertape .right-panel h1 {
  font-size: 40vh;
  font-weight: normal;
}
.error-container-zones .left-panel {
  width: 33vw;
}
.error-container-zones .left-panel img {
  height: 17vh;
}
.error-container-zones h1 {
  font-size: 3em;
  font-weight: 900;
  margin-bottom: 6vh;
}
.error-container-zones h2 {
  font-size: 2em;
  margin-bottom: 2vh;
}
.error-container-zones p {
  font-size: 1.5em;
  margin-bottom: 1vh;
  color: #777;
}
@media screen and (orientation: portrait) {
  .error-container {
    flex-direction: column;
 }
  .error-container .left-panel {
    height: 40vh;
    width: 100vw;
 }
  .error-container .left-panel img {
    width: 20vw;
 }
  .error-container .right-panel {
    height: 60vh;
    align-items: center;
    justify-content: flex-start;
    padding-top: 8vh;
 }
}
.error-actions {
  margin-top: 4vh;
}

/**
  * NB
  * - Only place global styles here. Styles for individual components should live in each component's folder.
  * - normalize.css is first imported via NPM in index.ts
  */

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px; /* i.e. 1rem */
}

body {
  font-family: Lato, "sans-serif", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

