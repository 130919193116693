.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
img.non-scrolling.non-zoomed {
  width: 100vw;
  height: 100vh;
  object-fit: contain;
}
.last-refresh-timestamp {
  bottom: 2%;
  position: absolute;
  right: 2%;
  background: #fccf00;
  padding: 5px;
  border-radius: 10px;
  font-size: 14px;
}
.last-refresh-timestamp-text {
  display: inline-block;
  padding-top: 1px;
  padding-right: 5px;
  padding-left: 4px;
}
.last-refresh-timestamp-job-error {
  bottom: 2%;
  position: absolute;
  right: 2%;
  background: #f03;
  padding: 5px;
  border-radius: 10px;
  font-size: 14px;
  color: #fff;
}
.last-refresh-timestamp-job-error-warning-icon {
  width: 20px;
  height: 20px;
  margin-right: 6px;
  padding-left: 4px;
  float: left;
 /** Same as color: #ffffff **/
  filter: invert(100%) sepia(100%) saturate(29%) hue-rotate(116deg) brightness(106%) contrast(108%);
}
